import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

// const Image = () => {
//   const data = useStaticQuery(graphql`
//     query {
//       placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 300) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `)

//   return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
// }

const ImageList = ({ images }) => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fixed(width: 120) {
              originalName
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
      # placeholderImage: file(relativePath: { eq: "gatsby-astronaut.png" }) {
      #   childImageSharp {
      #     fluid(maxWidth: 300) {
      #       ...GatsbyImageSharpFluid
      #     }
      #   }
      # }
    }
  `)

  // console.log("data imageList", data, images)

  const filteredImages = data.allImageSharp.edges
    .filter(edge => images.includes(edge.node.fixed.originalName))
    .map(imageObj => (
      <Img key={imageObj.node.fixed.originalName} fixed={imageObj.node.fixed} />
    ))
  // console.log("filteredImages", filteredImages);
  // return "1"
  return filteredImages
  // return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default ImageList
