import React from "react"
import PropTypes from "prop-types"

const FormHelp = ({ message, style, isInvalid }) => (
  <span className="form__help-block" style={style}>
    {message}
  </span>
)

FormHelp.propTypes = {
  message: PropTypes.string,
  style: PropTypes.object,
}

FormHelp.defaultProps = {
  message: "",
  style: {},
}

export default FormHelp
