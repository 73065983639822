import React from "react"
import PropTypes from "prop-types"

// import { Link } from 'react-router-dom';

const FormError = ({ errorText }) => {
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <h2>Tyvärr, något blev fel med formuläret</h2>
      <p>
        Felmeddelande: {errorText}
        <br />
        Försök gärna igen.
      </p>
      {/** <Link to="/">{t('error.form.back')}</Link> */}
    </div>
  )
}

FormError.propTypes = {
  errorText: PropTypes.string,
}

FormError.defaultProps = {
  errorText: "",
}

export default FormError
