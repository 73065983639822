import React from "react"
import { graphql, Link } from "gatsby"
// import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Form from "../components/form"

import ImageList from "../components/image-list"

import "./whitelabel.scss"
import "./../styles/color-schema.scss"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  // console.log("data", data)
  const { markdownRemark } = data // data.markdownRemark holds our post data
  // console.log("markdownRemark", markdownRemark)
  const { frontmatter, html } = markdownRemark
  // console.log("frontmatter", frontmatter)

  return (
    <Layout>
      <SEO
        title={frontmatter.seo_title}
        description={frontmatter.seo_meta}
        favicon={frontmatter.favicon}
        color_schema={frontmatter.systemId}
      />
      <div className="template__wrapper">
        <div className="template">
          <div className="template__cols">
            <div className="template__col">
              <div className="t-c">
                <Img fixed={data.logoImage.childImageSharp.fixed} />
              </div>
              {data.screenshotImage ? (
                <div className="template__screenshot">
                  <Img fluid={data.screenshotImage.childImageSharp.fluid} />
                </div>
              ) : null}
              {html ? (
                <div
                  className="template__container"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              ) : null}
            </div>
            <div className="template__col">
              <h1 className="t-c">Boka demo</h1>
              <p style={{ marginBottom: "10px" }}>
                När du fyllt i dina uppgifter kommer du bli kontaktad av en av
                våra duktiga experter som visar dig systemet, helt gratis.
              </p>
              {frontmatter.form_text ? (
                <p style={{ fontSize: "0.8rem", lineHeight: 1.4 }}>
                  {frontmatter.form_text}{" "}
                  <Link to={frontmatter.form_link}>
                    {frontmatter.form_link_name}
                  </Link>
                </p>
              ) : null}
              <Form id={frontmatter.systemId} />
            </div>
          </div>
          <div className="template__wide t-c">
            <div>
              <h3
                className=""
                style={{
                  fontWeight: 300,
                }}
                dangerouslySetInnerHTML={{ __html: frontmatter.usp_text }}
              ></h3>
            </div>
            {frontmatter.logos && frontmatter.logos.length ? (
              <div className="template__logos">
                <ImageList images={frontmatter.logos} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!, $logo: String, $screenshot: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        systemId
        date(formatString: "MMMM DD, YYYY")
        path
        title
        seo_title
        seo_meta
        favicon
        usp_text
        form_text
        form_link
        form_link_name
        logo
        logos
      }
    }
    logoImage: file(relativePath: { eq: $logo }) {
      childImageSharp {
        fixed(height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    screenshotImage: file(relativePath: { eq: $screenshot }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
