import React from "react"
import { Link } from "gatsby"

import FormValidator from "./form/formValidator"
import FormHelp from "./form/formHelp"
import FormError from "./form/formError"

const saveInquiryDb = async ({ data }) => {
  const query = JSON.stringify({
    query: `mutation {
      createInquiryBookADemo(
        data: "${data}"
      ) { result }
    }
    `,
  })
  // console.log("query", query)

  // console.log("process.env.GATSBY_BE_URL", process.env.GATSBY_BE_URL)
  const response = await fetch(process.env.GATSBY_BE_URL, {
    headers: { "content-type": "application/json" },
    method: "POST",
    body: query,
  })

  const responseJson = await response.json()
  // console.log("responseJson", responseJson)

  return responseJson.data
}

const formFieldsObj = {
  pipedrive: [
    "firstName",
    "lastName",
    "company",
    "licenses",
    "email",
    "phone",
    "consent",
  ],
  rest: ["firstName", "lastName", "company", "email", "phone", "consent"],
}

const formFieldsMarkup = {
  pipedrive: ["name", "company", "licenses", "email", "phone", "consent"],
  rest: ["name", "company", "email", "phone", "consent"],
}

const formValidationsFields = [
  {
    field: "firstName",
    method: "isEmpty",
    validWhen: false,
    message: "Vänligen, fyll i ditt förnamn.",
  },
  {
    field: "lastName",
    method: "isEmpty",
    validWhen: false,
    message: "Vänligen, fyll i ditt efternamn.",
  },
  {
    field: "company",
    method: "isEmpty",
    validWhen: false,
    message: "Vänligen, fyll i ditt företagsnamn.",
  },
  {
    field: "licenses",
    method: "isEmpty",
    validWhen: false,
    message: "Vänligen, fyll i antal licenser.",
  },
  {
    field: "email",
    method: "isEmpty",
    validWhen: false,
    message: "Vänligen, fyll i din epost.",
  },
  {
    field: "email",
    method: "isEmail",
    validWhen: true,
    message: "Vänligen, fyll i din korrekta epost.",
  },
  {
    field: "phone",
    method: "isEmpty",
    validWhen: false,
    message: "Vänligen, fyll i ditt telefonnummer.",
  },
  {
    field: "consent",
    method: "equals",
    args: ["true"],
    validWhen: true,
    message: "Vänligen, fyll i denna annars kan vi inte kontakta dig.",
  },
]

const FormName = ({ firstName, lastName, handleUpdate, renderValid }) => (
  <div className="cols form__row">
    <div className="col">
      <input
        type="text"
        name="firstName"
        id="firstName"
        placeholder="Förnamn"
        className={`input__text ${
          renderValid.firstName.isInvalid ? "error" : "valid"
        }`}
        value={firstName}
        onChange={handleUpdate}
      />
      <FormHelp
        message={renderValid.firstName.message}
        isInvalid={renderValid.firstName.isInvalid}
        style={{
          position: "relative",
          // bottom: "10px",
          // left: 0,
          // right: 0,
        }}
      />
    </div>
    <div className="col">
      <input
        type="text"
        name="lastName"
        id="lastName"
        placeholder="Efternamn"
        className={`input__text ${
          renderValid.lastName.isInvalid ? "error" : "valid"
        }`}
        value={lastName}
        onChange={handleUpdate}
      />
      <FormHelp
        message={renderValid.lastName.message}
        isInvalid={renderValid.lastName.isInvalid}
        style={{
          position: "relative",
          // bottom: "10px",
          // left: 0,
          // right: 0,
        }}
      />
    </div>
  </div>
)

const FormCompany = ({ company, handleUpdate, renderValid }) => (
  <div className="form__row">
    <input
      type="text"
      name="company"
      id="company"
      placeholder="Företagsnamn"
      className={`input__text ${
        renderValid.company.isInvalid ? "error" : "valid"
      }`}
      value={company}
      onChange={handleUpdate}
    />
    <FormHelp
      message={renderValid.company.message}
      isInvalid={renderValid.company.isInvalid}
      style={{
        position: "relative",
        // bottom: "10px",
        // left: 0,
        // right: 0,
      }}
    />
  </div>
)

const FormLicenses = ({ licenses, handleUpdate, renderValid }) => (
  <div className="form__row">
    <input
      type="number"
      min="0"
      name="licenses"
      id="licenses"
      placeholder="Antal licenser"
      className={`input__text ${
        renderValid.licenses.isInvalid ? "error" : "valid"
      }`}
      value={licenses}
      onChange={handleUpdate}
    />
    <FormHelp
      message={renderValid.licenses.message}
      isInvalid={renderValid.licenses.isInvalid}
      style={{
        position: "relative",
        // bottom: "10px",
        // left: 0,
        // right: 0,
      }}
    />
  </div>
)

const FormEmail = ({ email, handleUpdate, renderValid }) => (
  <div className="form__row">
    <input
      type="email"
      name="email"
      id="email"
      placeholder="E-postadress"
      className={`input__text ${
        renderValid.email.isInvalid ? "error" : "valid"
      }`}
      value={email}
      onChange={handleUpdate}
    />
    <FormHelp
      message={renderValid.email.message}
      isInvalid={renderValid.email.isInvalid}
      style={{
        position: "relative",
        // bottom: "10px",
        // left: 0,
        // right: 0,
      }}
    />
  </div>
)

const FormPhone = ({ phone, handleUpdate, renderValid }) => (
  <div className="form__row">
    <input
      type="tel"
      name="phone"
      id="phone"
      placeholder="Telefonnummer"
      className={`input__text ${
        renderValid.phone.isInvalid ? "error" : "valid"
      }`}
      value={phone}
      onChange={handleUpdate}
    />
    <FormHelp
      message={renderValid.phone.message}
      isInvalid={renderValid.phone.isInvalid}
      style={{
        position: "relative",
        // bottom: "10px",
        // left: 0,
        // right: 0,
      }}
    />
  </div>
)

const FormConsent = ({
  consent,
  consentTextOpen,
  handleUpdate,
  toggleConsentTextOpen,
  renderValid,
}) => (
  <div className="form__row">
    <input
      type="checkbox"
      name="consent"
      id="consent"
      checked={consent}
      onChange={handleUpdate}
      className={`input__checkbox ${
        renderValid.consent.isInvalid ? "error" : "valid"
      }`}
    />
    <label htmlFor="consent" className="input__checkbox__text">
      Jag har läst och accepterar hur{" "}
      <button
        type="button"
        className="btn--nostyle"
        onClick={toggleConsentTextOpen}
      >
        Boka demo
      </button>{" "}
      hanterar personuppgifter
    </label>
    <FormHelp
      message={renderValid.consent.message}
      isInvalid={renderValid.consent.isInvalid}
      style={{
        position: "relative",
        // bottom: "10px",
        // left: 0,
        // right: 0,
      }}
    />
    {consentTextOpen ? (
      <div className="form__consent-text">
        <h3>Behandling av personuppgifter – Bokademo.nu</h3>
        <p>
          Vid ett kostnadsfritt test och vid en förfrågan av demo samtycker jag
          till att mina personuppgifter, inklusive namn, e-postadress,
          telefonnummer för samtal och sms och eventuella andra digitala
          adresser får lagras och användas av bokademo.nu, som ägs av
          BusinessWith Sweden AB. Syftet är att förmedla kontaktuppgifter till
          systemleverantören så att de kan utföra en demo av sin produkt,
          anpassa innehåll, och för statistik- och analysändamål.
        </p>
        <p>
          Uppgifter som jag direkt eller indirekt lämnar genom att använda
          kontaktformuläret hos bokademo.nu kan tillsammans med andra
          kunduppgifter användas för systemleverantören för
          marknadsföringsändamål och för att anpassa innehåll, tjänster och
          annonsering till mig samt för utveckling av nya tjänster.
        </p>
        <p>Samtycket är giltigt till dess jag själv säger upp det.</p>
        <p>
          Jag är införstådd med att jag, när som helst och utan att uppge skäl,
          har rätt att återkalla samtycket, vilket jag gör genom att maila till
          adressen ("Ändra till bokademo.nu") info@businesswith.se.
        </p>
        <p>
          För ytterligare information om hur Bokademo.nu, behandling av
          personuppgifter, läs mer här <Link to="/legal/">Legal sidan</Link>
        </p>
      </div>
    ) : null}
  </div>
)

class Form extends React.Component {
  constructor(props) {
    super(props)

    // console.log("process.env", process.env.NODE_ENV)
    // console.log("be url", process.env.GATSBY_BE_URL)

    const { id } = props

    let fieldsType = id

    if (id !== "pipedrive") {
      fieldsType = "rest"
    }

    const validationsFields = formValidationsFields.filter(validation =>
      formFieldsObj[fieldsType].includes(validation.field)
    )

    this.validator = new FormValidator(validationsFields)

    this.state = {
      firstName: "",
      lastName: "",
      company: "",
      licenses: "",
      email: "",
      phone: "",
      consent: false,
      loading: false,
      completed: false,
      error: false,
      errorText: "",
      validation: this.validator.valid(),
      consentTextOpen: false,
      formFields: formFieldsMarkup[fieldsType],
    }

    this.submitted = false

    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleUpdate(e) {
    const { type, value, checked, name } = e.target
    const inputValue = type === "checkbox" ? checked : value

    this.setState({
      [name]: inputValue,
    })
  }

  async handleSubmit(event, fn) {
    event.preventDefault()

    // console.log('handleSubmit', email, name, type, agreed);

    const validation = this.validator.validate(this.state)
    // console.log('handleSubmit - validation', validation);

    this.setState({ validation })
    this.submitted = true

    if (validation.isValid) {
      this.setState({ loading: true })
      const {
        firstName,
        lastName,
        licenses,
        company,
        email,
        phone,
      } = this.state
      const { id } = this.props
      // const { type, guideId, handleTrackingClick } = this.props

      try {
        const data = encodeURIComponent(
          JSON.stringify({
            category: "CRM",
            // system: systemId,
            systemNameId: id,
            inquiryType: "DEMOREQUEST",
            firstName,
            lastName,
            company,
            licenses,
            email,
            phone,
            acceptAgree: true,
            url: document.location.href,
          })
        )

        const save = await saveInquiryDb({ data })
        // console.log("save", save)

        if (
          save &&
          save.createInquiryBookADemo &&
          save.createInquiryBookADemo.result &&
          save.createInquiryBookADemo.result === "done"
        ) {
          this.setState({ completed: true, loading: false })
        }

        const fieldsObject = {
          hitType: "event",
          eventCategory: "Form",
          eventAction: "Form submitted",
          eventLabel: id,
        }

        if (window && window.ga) {
          window.ga("send", fieldsObject)
        }

        // handleTrackingClick(null, 'Email form', `saved: ${type}`, `${type}`);
      } catch (error) {
        console.warn(error)
      }
    }
  }

  render() {
    const {
      firstName,
      lastName,
      company,
      licenses,
      email,
      phone,
      consent,
      loading,
      completed,
      error,
      errorText,
      validation,
      consentTextOpen,
      formFields,
    } = this.state

    const renderValid = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : validation // otherwise just use what's in state
    // console.log('renderValid', renderValid);

    if (error) {
      return <FormError errorText={errorText} />
    }

    if (completed) {
      return (
        <div className="notification notification--success notification--border">
          <p style={{ margin: "0 0 8px 0", fontSize: "16px" }}>
            Tack för demoförfrågan.
          </p>
          <p style={{ margin: 0, fontSize: "16px" }}>
            Vi hör av oss så snart vi kan.
          </p>
        </div>
      )
    }

    if (loading) {
      // console.log('mutation loading');
      return <div className="t-c">Laddar...</div>
    }

    const fields = formFields.map(field => {
      switch (field) {
        case "name":
          return (
            <FormName
              key={field}
              firstName={firstName}
              lastName={lastName}
              handleUpdate={this.handleUpdate}
              renderValid={renderValid}
            />
          )

        case "company":
          return (
            <FormCompany
              key={field}
              company={company}
              handleUpdate={this.handleUpdate}
              renderValid={renderValid}
            />
          )

        case "licenses":
          return (
            <FormLicenses
              key={field}
              licenses={licenses}
              handleUpdate={this.handleUpdate}
              renderValid={renderValid}
            />
          )

        case "email":
          return (
            <FormEmail
              key={field}
              email={email}
              handleUpdate={this.handleUpdate}
              renderValid={renderValid}
            />
          )

        case "phone":
          return (
            <FormPhone
              key={field}
              phone={phone}
              handleUpdate={this.handleUpdate}
              renderValid={renderValid}
            />
          )

        case "consent":
          return (
            <FormConsent
              key={field}
              consent={consent}
              consentTextOpen={consentTextOpen}
              toggleConsentTextOpen={() =>
                this.setState({ consentTextOpen: !consentTextOpen })
              }
              handleUpdate={this.handleUpdate}
              renderValid={renderValid}
            />
          )

        default:
          return (
            <p key={field} className="text--center text--error">
              något är fel - {field}
            </p>
          )
      }
    })

    return (
      <form
        method="post"
        className="form-demo"
        onSubmit={e => this.handleSubmit(e)}
      >
        {fields}

        <div className="form__row">
          <button type="submit" className="btn btn--submit">
            Boka nu
          </button>
        </div>
      </form>
    )
  }
}

export default Form
